
	import { Component, Vue, Watch } from "vue-property-decorator";
	import { ECharts, EChartsOption, init } from "echarts";
	import { Page, Table, TableColumn, Modal, Input, DatePicker } from "view-design";
	@Component({
        components:{
            Page, Table, Modal, Input, DatePicker
        }
    })
		export default class deviceMain2 extends Vue {
            colors = ["#4261ED", "#EF9F24", "#c4ccD6", "#FD4848"];
            activeType:any = "0";
            tableLive = 0;
            myheight = 180;
            myheight2 = 300;
            showDevice = false;
            deviceTypes:any = [
                {
                    title: "VRV室内机",
                    number: 256
                },
                {
                    title: "室外机多联机",
                    number: 10
                }
            ]
            column1:TableColumn[] = [
                {
                    title: "编号",
                    key: "number",
                    align: "center",
                    width: 95
                },
                {
                    align: "center",
                    title: "名称",
                    key: "name"
                    // width: 200
                },
                {
                    align: "center",
                    title: "位置",
                    key: "position"
                },
                {
                    align: "center",
                    title: "时间",
                    key: "time",
                    width: 85
                }
            ]
            data1:any = [
                {
                    number: "2079392",
                    name: "室内机多联机MDV-C系列",
                    position: "3F教师办公室 ",
                    time: "23.10.10 10 : 10"
                },
                {
                    number: "2079392",
                    name: "室内机多联机MDV-C系列",
                    position: "1F休息室 ",
                    time: "23.10.10 10 : 10"
                },
                {
                    number: "2079392",
                    name: "室内机多联机MDV-C系列",
                    position: "3F前室 ",
                    time: "23.10.10 10 : 10"
                },
                {
                    number: "2079392",
                    name: "室内机多联机MDV-C系列",
                    position: "3F教师办公室 ",
                    time: "23.10.10 10 : 10"
                },
                {
                    number: "2079392",
                    name: "室内机多联机MDV-C系列",
                    position: "1F休息室 ",
                    time: "23.10.10 10 : 10"
                },
                {
                    number: "2079392",
                    name: "室内机多联机MDV-C系列",
                    position: "3F前室 ",
                    time: "23.10.10 10 : 10"
                }
            ]
            column2:TableColumn[] = [
                {
                    title: "编号",
                    key: "number",
                    align: "center",
                    width: 95
                },
                {
                    align: "center",
                    title: "名称",
                    key: "name"
                },
                {
                    align: "center",
                    title: "当前状态",
                    slot: "status",
                    width: 95
                },
                {
                    align: "center",
                    title: "位置",
                    key: "position"
                },
                {
                    align: "center",
                    title: "累计报警（次）",
                    key: "ljbj",
                    width: 135
                },
                {
                    align: "center",
                    title: "累计维修（次）",
                    key: "ljwx",
                    width: 135
                },
                {
                    align: "center",
                    title: "累计维保（次）",
                    key: "ljwb",
                    width: 135
                },
                {
                    align: "center",
                    title: "型号规格",
                    key: "typenumber",
                    width: 250
                }
            ]
            data2:any=[
                {
                    number: "2079352",
                    name: "室内机多联机MDV-C系列",
                    status: 0,
                    position: "3F教师办公室",
                    ljbj: 5,
                    ljwx: 5,
                    ljwb: 10,
                    typenumber: "MDVL-450(16)W/D2SN1-891-800"
                },
                {
                    number: "2079352",
                    name: "室内机多联机MDV-C系列",
                    status: 0,
                    position: "1F休息室",
                    ljbj: 5,
                    ljwx: 5,
                    ljwb: 10,
                    typenumber: "MDVL-450(16)W/D2SN1-891-800"
                },
                {
                    number: "2079352",
                    name: "室内机多联机MDV-C系列",
                    status: 0,
                    position: "3F前室",
                    ljbj: 5,
                    ljwx: 5,
                    ljwb: 10,
                    typenumber: "MDVL-450(16)W/D2SN1-891-800"
                },
                {
                    number: "2079352",
                    name: "室内机多联机MDV-C系列",
                    status: 0,
                    position: "1F休息室",
                    ljbj: 5,
                    ljwx: 5,
                    ljwb: 10,
                    typenumber: "MDVL-450(16)W/D2SN1-891-800"
                },
                {
                    number: "2079352",
                    name: "室内机多联机MDV-C系列",
                    status: 0,
                    position: "3F前室",
                    ljbj: 5,
                    ljwx: 5,
                    ljwb: 10,
                    typenumber: "MDVL-450(16)W/D2SN1-891-800"
                },
                {
                    number: "2079352",
                    name: "室内机多联机MDV-C系列",
                    status: 0,
                    position: "1F休息室",
                    ljbj: 5,
                    ljwx: 5,
                    ljwb: 10,
                    typenumber: "MDVL-450(16)W/D2SN1-891-800"
                },
                {
                    number: "2079352",
                    name: "室内机多联机MDV-C系列",
                    status: 0,
                    position: "3F前室",
                    ljbj: 5,
                    ljwx: 5,
                    ljwb: 10,
                    typenumber: "MDVL-450(16)W/D2SN1-891-800"
                }
            ]
            data3:any = [
                {
                    number: "2079352",
                    name: "室内机多联机MDV-C系列",
                    status: 0,
                    position: "1F102",
                    ljbj: 5,
                    ljwx: 5,
                    ljwb: 10,
                    typenumber: "MDVL-450(16)W/D2SN1-891-800"
                }
            ]
            isDrawBack:boolean = false;
            clickTable () {
                // alert(123);
                this.showDevice = true;
                this.$nextTick(() => {
					const data = [12, 13, 10, 13, 9, 23, 21];
					const data1 = [15, 23, 20, 15, 19, 33, 41];
                    this.setdeviceChart("device_chart1", "报警曲线图", "报警", data, ["#fac858"]);
                    this.setdeviceChart("device_chart2", "维修趋势图", "维修", data1, ["#90cb75"]);
                });
            }
        clickType (item, index) {
            this.activeType = index;
        }
        setdeviceChart (domName, title, dataName, data, color = ["blue"]) {
			const myChart: HTMLElement = document.getElementById(domName) as HTMLElement;
			// 获取dom，断言HTMLElement类型，否则会报错
			const chart = init(myChart);
			let myOption:any = {
                color: color,
                title: {
                    text: title,
                    textStyle: {
						color: "#333",
						fontWeight: 600,
						fontSize: 15
					}
                },
				legend: {
					data: [dataName],
                    textStyle: { // 图例文字的样式
						color: "#788CA1",
						fontSize: 12
					}
				},
				grid: {
					left: "3%",
					right: "4%",
					bottom: "3%",
					containLabel: true
				},
				xAxis: {
					type: "category",
					boundaryGap: false,
                    axisLine: {
						show: true,
						lineStyle: {
							color: "#c4ccD6"
						}
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: "dashed",
                            color: ["#e0e2e3"]
						}
					},
					axisTick: {
						lineStyle: {
							width: 0
						}
					},
                    axisLabel: {
						color: "#788CA1"
					},
					data: ["7-12", "7-13", "7-14", "7-15", "7-16", "7-17", "7-18"]
				},
				yAxis: {
					type: "value",
                    nameTextStyle: {
						fontSize: 10,
						align: "center"
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: "dashed",
                            color: ["#e0e2e3"]
						}
					},
					axisLine: {
						show: true,
						lineStyle: {
							color: "#c4ccD6"
						}
					},
                    axisLabel: {
						color: "#788CA1"
					}
				},
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        label: {
                            backgroundColor: "#6a7985"
                        }
                    }
                },
				series: [
					{
						name: dataName,
						type: "line",
                        smooth: true,
					    symbol: "none",
						data: data,
                        areaStyle: {
                            opacity: 0.5,
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: color[0] // 0% 处的颜色
                                }, {
                                    offset: 1, color: "#ffffff" // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }
                        }
					}
				]
			};
			chart.setOption(myOption);
		}
        setChart3 (domName) {
			const myChart: HTMLElement = document.getElementById(domName) as HTMLElement;
			// 获取dom，断言HTMLElement类型，否则会报错
			const chart = init(myChart);
			let myOption:any = {
                legend: {
                    data: ["vrv室内机", "室外机多联机"],
                    itemWidth: 13,
                    itemHeight: 13,
                    textStyle: {
                        color: "#788CA1"
                    },
                    top: 0
                },
				grid: {
                    top: "10%",
					left: "5%",
					right: "4%",
					bottom: "3%",
					containLabel: true
				},
				xAxis: {
					type: "category",
					boundaryGap: false,
                    axisLine: {
						show: true,
						lineStyle: {
							color: "#c4ccD6"
						}
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: "dashed",
                            color: ["#e0e2e3"]
						}
					},
					axisTick: {
						lineStyle: {
							width: 0
						}
					},
					axisLabel: {
						color: "#788CA1"
					},
					data: ["8-12", "8-13", "8-14", "8-15", "8-16", "8-17", "8-18"]
				},
				yAxis: {
					type: "value",
                    nameTextStyle: {
						fontSize: 10,
						align: "center"
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: "dashed",
                            color: ["#e0e2e3"]
						}
					},
					axisLine: {
						show: true,
						lineStyle: {
							color: "#c4ccD6"
						}
					},
                    axisLabel: {
						color: "#788CA1"
					}
				},
                tooltip: {
                    trigger: "axis"
                },
				series: [
					{
						name: "vrv室内机",
						type: "line",
                        smooth: true,
					    symbol: "none",
                        areaStyle: {
                            opacity: 0.5,
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: this.colors[0] // 0% 处的颜色
                                }, {
                                    offset: 1, color: "#ffffff" // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }
                        },
						data: [10, 12, 15, 16, 8, 20, 20]
					},
                    {
						name: "室外机多联机",
						type: "line",
                        smooth: true,
					    symbol: "none",
						data: [12, 13, 10, 13, 9, 23, 21],
                        areaStyle: {
                            opacity: 0.5,
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: this.colors[1] // 0% 处的颜色
                                }, {
                                    offset: 1, color: "#ffffff" // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }
                        }
					}
				],
                color: this.colors
			};
			chart.setOption(myOption);
		}
		mounted () {
            this.$store.commit("changeDraw", true);
            this.$nextTick(() => {
                this.myheight = document.querySelector("body").clientHeight * 0.3 - 110;
                this.myheight2 = document.querySelector("body").clientHeight * 0.3 - 100;
                this.setChart3("chart1");
			    this.setChart3("chart2");
            });
		}
        @Watch("$store.state.shebeiModal")
        changeshebeiModal () {
            this.clickTable();
        }
	}
